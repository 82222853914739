html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
    letter-spacing: -1.5px;
}

html,
body {
    color: #000000;
    background: rgb(255, 255, 255);

}

.submit-button,
.date-holder {
    background-color: black;
}

html {
    scroll-behavior: smooth;
}

.navbar-toggler {
    border-radius: 0;
    cursor: pointer;
    user-select: none;
}

#hamburger {
    text-align: right;
}

#khadaver-anchor {
    border: none;
}

#khadaver-anchor:hover {
    border: none;
}

.border-black {
    border-color: #000000 !important;
}

.art-img {
    object-fit: contain;
    height: 75dvh;
    margin-left: auto;
    background-color: black;
}

.gallery-img {
    object-fit: cover;
    aspect-ratio: 1/1;
    margin-left: auto;
    background-color: black;
}

.modal-img {
    object-fit: contain;
    height: 90dvh;
    background-color: transparent;
}

.blog-title {
    background-size: cover;
    user-select: none;
    cursor: pointer;
    border-color: black !important;
    -webkit-text-stroke-width: 3px;
    -webkit-text-stroke-color: black;
}

.khad-definition {
    border-color: black !important;
}

.blog-text {
    height: 40dvh;
    border-color: black !important;
    animation: .5s ease-out 0s 1 slideInFromTop;
    background-image: url(./artimages/blogtextbg.jpg);
    background-size: cover;
}

#insta-icon {
    height: 50px;
}


ul {
    list-style-type: none;
}

a {
    border: solid transparent;
    text-decoration: none;
    color: black;
}


#big-follow:hover {
    border: none;
}

.active-nav {
    filter: invert(1);
}

.btn {
    border-radius: 0;
}




::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #000000;
}

@keyframes slideInFromTop {
    0% {
        transform: translateY(-10%);
    }

    100% {
        transform: translateX(0);
    }
}




@media (max-width: 768px) {
    .gallery-img {
        object-fit: cover;
        height: 20dvh;
        margin-left: auto;
        background-color: transparent;
    }

    .art-img {
        height: 50vh;
    }

    .blog-title {
        -webkit-text-stroke-width: 2px;
        -webkit-text-stroke-color: black;
    }
}